import React, { useState } from "react";
import { Box, Button, Text, Collapse, VStack, HStack } from "@chakra-ui/react";
import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import Step1Content from "./Step1Content";
// import Step2Content from "./Step2Content";

const OnboardingCard = ({}) => {
  // const [showSteps, setShowSteps] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  
  // Only show the onboarding card if the user has no companies
  const hasCompanies = user && user.companies && user.companies.length > 0;
  
  if (!showOnboarding || hasCompanies) {
    return null;
  }

  const handleStartOnboarding = () => {
    // redirect to /onboarding page
    navigate("/onboard2");
    // setShowSteps(true);
  };

  const handleDismiss = () => {
    setShowOnboarding(false);
  };

  // Define handleCompanyData function to process API response
  const handleCompanyData = (data) => {
    // Process and populate fields based on API response
  };

  // setup the step content
  // const step1Content = <Step1Content onAutocomplete={handleCompanyData} />;
  // const step2Content = <Step2Content />;
  // const step3Content = <h1>Step 3 Content</h1>;
  // const step4Content = <h1>Step 4 Content</h1>;

  // // setup step validators, will be called before proceeding to the next step
  // function step2Validator() {
  //   // return a boolean
  // }

  // function step3Validator() {
  //   // return a boolean
  // }

  // function step4Validator() {
  //   // return a boolean
  // }

  // function onFormSubmit() {
  //   // handle the submit logic here
  //   // This function will be executed at the last step
  //   // when the submit button (next button in the previous steps) is pressed
  // }

  return (
    <Box p={4} bg="#ccb3d6" borderRadius={10} mb={4}>
      <Text fontSize="lg">Bine ai venit pe platformă!</Text>
      <Text>
        Pentru a profita cu adevărat de platformă, îți recomandăm să începi cu
        procesul de onboarding.
      </Text>
      <HStack
        spacing={4}
        mt={4}
        flexDirection={["column", "row", "row", "row", "row"]}
      >
        <Button colorScheme="purple" onClick={handleStartOnboarding}>
          Începe onboarding-ul{" "}
        </Button>
        <Button colorScheme="gray" textColor="gray" onClick={handleDismiss}>
          Mai târziu{" "}
        </Button>
      </HStack>

      {/* <Collapse in={showSteps} animateOpacity>
        <VStack spacing={4} mt={4}>
          <StepProgressBar
            startingStep={0}
            onSubmit={onFormSubmit}
            steps={[
              {
                label: "Date generale",
                name: "step 1",
                content: step1Content,
                validator: step2Validator.step1Validator,
              },
              {
                label: "Date financiare",
                name: "step 2",
                content: step1Content,
                validator: step3Validator.step1Validator,
              },
              {
                label: "Call to Action",
                name: "step 3",
                content: step1Content,
                validator: step4Validator.step1Validator,
              },
              // ... other steps
            ]}
          />
        </VStack>
      </Collapse> */}
    </Box>
  );
};

export default OnboardingCard;
