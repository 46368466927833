import { 
  FormControl, 
  FormLabel, 
  Grid, 
  Input, 
  Select, 
  Skeleton,
  Button,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
  Box,
  Heading,
  Divider,
  HStack,
  List,
  ListItem,
  Switch,
  Link
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../../../services/axios";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { FaLightbulb } from "react-icons/fa";
import FeedbackModal from '../../Common/FeedbackModal';
import { useSelector, useDispatch } from "react-redux";
import { updateUserSuccess, setCompanies } from "../../../reducers/authReducer";

function AccountSettings({ data, updateData, loading }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isDeleteCompanyOpen, setIsDeleteCompanyOpen] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true,
    performance: true,
    functionality: true,
    marketing: false,
  });
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((state) => state.auth.user);
  const companies = useSelector((state) => state.auth.companies)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    const savedPreferences = localStorage.getItem('cookiePreferences');
    if (savedPreferences) {
      try {
        const parsed = JSON.parse(savedPreferences);
        setCookiePreferences(prev => ({
          ...prev,
          ...parsed,
          essential: true // Always keep essential true
        }));
      } catch (error) {
        console.error('Error parsing saved cookie preferences:', error);
      }
    }
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get('/companies/getAll');
      setCompanies(response.data);
    } catch (error) {
      toast({
        title: "Eroare",
        description: "Nu am putut încărca lista companiilor",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteCompany = async () => {
    try {
      console.log(selectedCompany);
      await axiosInstance.delete(`/companies/${selectedCompany._id}`);
      toast({
        title: "Companie ștearsă cu succes",
        description: "Compania a fost ștearsă definitiv",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteCompanyOpen(false);
      localStorage.removeItem("companies");
      localStorage.removeItem("current_company_id");
      
      // Update user's companies list in Redux
      const updatedCompanies = companies.filter(company => company._id !== selectedCompany._id);
      dispatch(setCompanies(updatedCompanies));

      // Update user data if needed
      if (user.companies) {
        const updatedUser = {
          ...user,
          companies: user.companies.filter(id => id !== selectedCompany._id)
        };
        dispatch(updateUserSuccess(updatedUser));
      }

      // If we're on the deleted company's page, redirect to feed
      const currentCompanyId = localStorage.getItem("current_company_id");
      if (currentCompanyId === selectedCompany._id) {
        navigate('/feed');
      }

      fetchCompanies(); // Refresh the list
    } catch (error) {
      toast({
        title: "Eroare",
        description: "Nu am putut șterge compania. Te rugăm să încerci din nou.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (field, value) => {
    updateData({
      ...data,
      [field]: value
    });
  };

  const handleDeleteAccount = async () => {
    try {
      await axiosInstance.delete('/users/me');
      toast({
        title: "Cont șters cu succes",
        description: "Contul tău a fost șters definitiv",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Clear local storage and redirect to home
      localStorage.clear();
      navigate('/');
    } catch (error) {
      toast({
        title: "Eroare",
        description: "Nu am putut șterge contul. Te rugăm să încerci din nou.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveCookiePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
    
    toast({
      title: "Preferințe salvate",
      description: "Preferințele pentru cookie-uri au fost salvate cu succes.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSavePersonal = async () => {
    try {
      const personalData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        name: data.name,
        profile_picture: data.profilePicture
      }
      
      const response = await axiosInstance.post('/users/update2', personalData)
      if (response.data) {
        // Update Redux store with the new user data
        const userResponse = await axiosInstance.get('/users/me')
        dispatch(updateUserSuccess(userResponse.data))

        toast({
          title: "Success",
          description: "Personal information updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        return true
      }
      return false
    } catch (error) {
      console.error("Error saving personal data:", error)
      toast({
        title: "Error",
        description: "Could not save personal information. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return false
    }
  }

  return (
    <VStack spacing={8} align="stretch">
      <HStack justify="space-between" align="center">
        <Heading size="md">Setări Cont</Heading>
        <Button
          leftIcon={<FaLightbulb />}
          colorScheme="blue"
          variant="outline"
          onClick={() => setIsFeedbackOpen(true)}
        >
          Ai o sugestie?
        </Button>
      </HStack>

      <Box borderTop="1px" borderColor="gray.200" pt={6}>
        <Heading size="md" mb={4}>
          Informații Personale
        </Heading>
        <Text color="gray.600" mb={6}>
          Actualizează-ți informațiile personale și detaliile de contact.
        </Text>
        
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={6}
          mb={6}
        >
          <FormControl id="firstName">
            <FormLabel>Nume</FormLabel>
            <Skeleton isLoaded={!loading}>
              <Input 
                focusBorderColor="brand.blue" 
                type="text" 
                placeholder="Ion"
                value={data.firstName || ""}
                onChange={(e) => handleChange("firstName", e.target.value)}
              />
            </Skeleton>
          </FormControl>
          <FormControl id="lastName">
            <FormLabel>Prenume</FormLabel>
            <Skeleton isLoaded={!loading}>
              <Input 
                focusBorderColor="brand.blue" 
                type="text" 
                placeholder="Ionescu"
                value={data.lastName || ""}
                onChange={(e) => handleChange("lastName", e.target.value)}
              />
            </Skeleton>
          </FormControl>
          <FormControl id="phoneNumber">
            <FormLabel>Numar telefon</FormLabel>
            <Skeleton isLoaded={!loading}>
              <Input
                focusBorderColor="brand.blue"
                type="tel"
                placeholder="(+40) 7XXXXXXX"
                value={data.phoneNumber || ""}
                onChange={(e) => handleChange("phoneNumber", e.target.value)}
              />
            </Skeleton>
          </FormControl>
          <FormControl id="emailAddress">
            <FormLabel>Email</FormLabel>
            <Skeleton isLoaded={!loading}>
              <Input
                focusBorderColor="brand.blue"
                type="email"
                placeholder="office@ctrl.fund"
                value={data.email || ""}
                disabled
              />
            </Skeleton>
          </FormControl>
          <FormControl id="job">
            <FormLabel>Functie</FormLabel>
            <Skeleton isLoaded={!loading}>
              <Input
                focusBorderColor="brand.blue"
                type="text"
                placeholder="Antreprenor"
                value={data.job || ""}
                onChange={(e) => handleChange("job", e.target.value)}
              />
            </Skeleton>
          </FormControl>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
          <Button 
            colorScheme="blue"
            onClick={handleSavePersonal}
            isLoading={loading}
            width={{ base: "100%", md: "auto" }}
          >
            Salvează Informațiile Personale
          </Button>
        </Box>
      </Box>

      <Box mt={8} pt={6} borderTop="1px" borderColor="gray.200">
        <Heading size="md" mb={4}>
          Preferințe și Termeni
        </Heading>
        
        <VStack spacing={6} align="stretch">
          <Box>
            <Heading size="sm" mb={4}>Preferințe Cookie-uri</Heading>
            <Text color="gray.600" mb={4}>
              Gestionează modul în care folosim cookie-urile pentru a-ți îmbunătăți experiența.
            </Text>
            <VStack spacing={4} align="stretch">
              <HStack justify="space-between">
                <Box>
                  <Text fontWeight="bold">Cookie-uri Esențiale</Text>
                  <Text fontSize="sm" color="gray.600">Necesare pentru funcționarea site-ului</Text>
                </Box>
                <Switch isChecked={true} isDisabled colorScheme="blue" />
              </HStack>

              <HStack justify="space-between">
                <Box>
                  <Text fontWeight="bold">Cookie-uri de Performanță</Text>
                  <Text fontSize="sm" color="gray.600">Pentru analiza și îmbunătățirea site-ului</Text>
                </Box>
                <Switch 
                  isChecked={cookiePreferences.performance}
                  onChange={(e) => setCookiePreferences({...cookiePreferences, performance: e.target.checked})}
                  colorScheme="blue"
                />
              </HStack>

              <HStack justify="space-between">
                <Box>
                  <Text fontWeight="bold">Cookie-uri de Funcționalitate</Text>
                  <Text fontSize="sm" color="gray.600">Pentru funcții personalizate</Text>
                </Box>
                <Switch 
                  isChecked={cookiePreferences.functionality}
                  onChange={(e) => setCookiePreferences({...cookiePreferences, functionality: e.target.checked})}
                  colorScheme="blue"
                />
              </HStack>

              <HStack justify="space-between">
                <Box>
                  <Text fontWeight="bold">Cookie-uri de Marketing</Text>
                  <Text fontSize="sm" color="gray.600">Pentru publicitate personalizată</Text>
                </Box>
                <Switch 
                  isChecked={cookiePreferences.marketing}
                  onChange={(e) => setCookiePreferences({...cookiePreferences, marketing: e.target.checked})}
                  colorScheme="blue"
                />
              </HStack>

              <Box display="flex" justifyContent="flex-end">
                <Button colorScheme="blue" onClick={saveCookiePreferences}>
                  Salvează Preferințele
                </Button>
              </Box>
            </VStack>
          </Box>

          <Divider />

          <Box>
            <Heading size="sm" mb={4}>Termeni și Condiții</Heading>
            <VStack spacing={3} align="stretch">
              <Text color="gray.600">
                Revizuiește documentele importante legate de utilizarea platformei noastre.
              </Text>
              <Link color="blue.500" href="/terms-and-conditions">
                Termeni și Condiții
              </Link>
              <Link color="blue.500" href="/cookie-policy">
                Politica de Cookie-uri
              </Link>
            </VStack>
          </Box>
        </VStack>
      </Box>

      <Box mt={8} pt={6} borderTop="1px" borderColor="gray.200">
        <Heading size="md" color="red.500" mb={4}>
          Acțiuni Irevesibile
        </Heading>
        <Text color="gray.600" mb={4}>
          Aceste acțiuni sunt ireversibile și pot duce la pierderea permanentă a datelor.
        </Text>
        
        <VStack spacing={6} align="stretch">
          {/* Company Deletion Section */}
          <Box>
            <Heading size="sm" mb={4}>Ștergere Companii</Heading>
            <Text color="gray.600" mb={4}>
              {companies.length > 0 
                ? "Selectează o companie pentru a o șterge permanent din sistem. Această acțiune nu poate fi anulată."
                : "Nu ai nicio companie adăugată în cont."
              }
            </Text>
            {companies.length > 0 ? (
              <List spacing={3}>
                {companies.map((company) => (
                  <ListItem key={company._id} p={3} borderWidth="1px" borderRadius="md">
                    <HStack justify="space-between">
                      <VStack align="start" spacing={1}>
                        <Text fontWeight="bold">{company.company_name}</Text>
                        <Text fontSize="sm" color="gray.600">CUI: {company.cif}</Text>
                      </VStack>
                      <Button
                        colorScheme="red"
                        size="sm"
                        leftIcon={<DeleteIcon />}
                        onClick={() => {
                          setSelectedCompany(company);
                          setIsDeleteCompanyOpen(true);
                        }}
                      >
                        Șterge
                      </Button>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            ) : null}
            <Button
              mt={4}
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={() => navigate('/onboard2')}
              size="sm"
            >
              Adaugă o companie nouă
            </Button>
          </Box>

          <Divider />

          {/* Account Deletion Section */}
          <Box>
            <Heading size="sm" mb={4}>Ștergere Cont</Heading>
            <FormControl>
              <Button
                colorScheme="red"
                variant="outline"
                onClick={onOpen}
                isLoading={loading}
                leftIcon={<DeleteIcon />}
              >
                Șterge contul permanent
              </Button>
            </FormControl>
          </Box>
        </VStack>
      </Box>

      {/* Delete Company Modal */}
      <Modal isOpen={isDeleteCompanyOpen} onClose={() => setIsDeleteCompanyOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmare ștergere companie</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Ești sigur că vrei să ștergi compania {selectedCompany?.company_name}? 
              Această acțiune este ireversibilă și va șterge toate datele asociate companiei.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={() => setIsDeleteCompanyOpen(false)}>
              Anulează
            </Button>
            <Button colorScheme="red" onClick={handleDeleteCompany}>
              Șterge compania
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Existing Account Delete Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmare ștergere cont</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Ești sigur că vrei să ștergi contul? Această acțiune este ireversibilă și va șterge toate datele asociate contului tău.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Anulează
            </Button>
            <Button colorScheme="red" onClick={handleDeleteAccount}>
              Șterge contul
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FeedbackModal
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        initialData={{
          email: user?.email,
        }}
        title="Trimite o sugestie"
        submitButtonText="Trimite sugestia"
        axiosInstance={axiosInstance}
      />
    </VStack>
  );
}

export default AccountSettings;
