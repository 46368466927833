import React, { useEffect, useState } from "react";
import Header from "../../Header";
import axiosInstance from "../../../../services/axios";
import {
  useToast,
  Button,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
import {
  withStepProgress,
  useStepProgress,
  Step,
  StepProgressBar,
} from "react-stepz";
import "react-stepz/dist/index.css";
import { Container } from "@chakra-ui/layout";
import { useDispatch } from "react-redux";
import {
  setCurrentCompany,
  setCompaniesSuccess,
  setUserSuccess
} from "../../../../reducers/authReducer";
import { useSelector } from 'react-redux';

import Step1Content from "../CompanyOnboard2/Step1Content";
import Step2Content from "../CompanyOnboard2/Step2Content2";
import Step3Content from "../CompanyOnboard2/Step3Content";

function Onboard() {
  // for the header
  const user = useSelector((state) => state.auth.user);
  const filters = useSelector((state) => state.auth.filters);
  const [searchResults, setSearchResults] = useState([]);
  const currentCompanyId = useSelector((state) => state.auth.current_company_id);

  const [showSteps, setShowSteps] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    step1: {},
    step2: {},
    step3: {},
  });
  const step1Validator = () => {
    const step1Data = formData.step1;
    let errors = [];

    // Validate CUI - assuming it should be a non-empty string
    if (!step1Data.cui || step1Data.cui.trim().length === 0) {
      errors.push("CUI-ul este necesar.");
    } else {
      if (!step1Data.companyName || step1Data.companyName.trim().length === 0) {
        errors.push("Numele companiei este necesar.");
      }

      // Validate companyDescription - assuming it should be a non-empty string
      if (
        !step1Data.companyDescription ||
        step1Data.companyDescription.trim().length === 0
      ) {
        errors.push("Descrierea companiei este necesara.");
      }

      // Validate companyRegion - assuming it should be a non-empty string
      if (!step1Data.companyRegions || step1Data.companyRegions.length === 0) {
        errors.push("Regiunea companiei este necesara.");
      }
    }

    // If there are any errors, display them and return false to indicate validation failure
    if (errors.length > 0) {
      toast({
        title: "Eroare de validare",
        description: errors.join("\n"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return false;
    }

    // If no errors, validation passed
    setStep(step + 1);
    return true;
  };
  const step2Validator = () => {
    const step2Data = formData.step2;
    let errors = [];

    if (!step2Data.purpose || !step2Data.purpose.length === 0) {
      errors.push("Motivul finantarii este necesar.");
    }
    // Validate companyRegion - assuming it should be a non-empty string
    if (!step2Data.purchases || step2Data.purchases.length === 0) {
      errors.push("Achizitiile finantarii sunt necesare.");
    }
    if (!step2Data.amount || step2Data.amount.length === 0) {
      errors.push("Suma dorita este necesara.");
    }
    // if (!step2Data.financingTypes || step2Data.financingTypes.length === 0) {
    //   errors.push("Cel putin un tip de finatare este necesara.");
    // }

    // If there are any errors, display them and return false to indicate validation failure
    if (errors.length > 0) {
      toast({
        title: "Eroare de validare",
        description: errors.join("\n"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return false;
    }

    // If no errors, validation passed
    setStep(step + 1);
    handleSubmit();
    return true;
  };
  const step3Validator = () => true;

  const steps = [
    {
      label: "Detalii companie",
      name: "step1",
      validator: step1Validator,
    },
    {
      label: "Detalii finantare",
      name: "step2",
      validator: step2Validator,
    },
    {
      label: "Cautare",
      name: "step3",
      validator: step3Validator,
    },
  ];

  console.log("Steps array: ", steps);
  const { stepForward, stepBackwards, currentIndex } = useStepProgress({
    steps,
    startingStep: 0,
  });

  function wrapperStepForward() {
    const res = stepForward();
  }

  function wrapperStepBarckwards() {
    const res = stepBackwards();
    setStep(step - 1);
  }

  // useEffect(() => {
  //   console.log(
  //     "currentIndex after useStepProgress initialization: ",
  //     currentIndex
  //   );
  // }, [currentIndex]);
  // useEffect(() => {
  //   console.log("formData: ", formData);
  // }, [formData]);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  function validateCUI(cui) {
    cui = cui.toString();

    if (!/^[0-9]+$/.test(cui) || cui.length < 2 || cui.length > 10) {
      return false; // Ensure CUI is numeric and length is within expected range
    }

    const controlDigit = parseInt(cui.charAt(cui.length - 1), 10);
    cui = cui.substring(0, cui.length - 1);

    if (cui.length < 2) {
      return false; // CUI without control digit must be at least 2 characters long
    }

    let sum = 0;
    const weights = [2, 3, 5, 7, 1, 2, 3, 5]; // The weights for the CUI calculation, not including the control digit
    let cuiRev = cui.split("").reverse().join(""); // Reverse CUI for calculation

    for (let i = 0; i < cuiRev.length; i++) {
      sum += parseInt(cuiRev[i], 10) * weights[i];
    }

    let calculatedControl = (sum * 10) % 11;
    if (calculatedControl === 10) {
      calculatedControl = 0; // If the result is 10, the control digit is 0
    }

    return controlDigit === calculatedControl;
  }

  const handleAutocomplete = async (cui) => {
    console.log("handleAutocomplete: ", cui);
    if (cui.startsWith("RO")) {
      cui = cui.substring(2);
      console.log("trimmed cui: ", cui);
    }
    if (!validateCUI(cui)) {
      toast({
        title: "CUI invalid.",
        description: "Acesta nu este un CUI valid.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      // First, check if the company already exists
      const checkResponse = await axiosInstance.get(`/companies/check/${cui}`, { withCredentials: true });

      if (checkResponse.data.exists) {
        // Company already exists, show error message
        toast({
          title: "Companie existentă",
          description: "Această companie a fost deja înregistrată.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        setIsLoading(false);
        return;
      }

      // If company doesn't exist, proceed with fetching data
      const url = `/companies/full/${cui}`;
      const response = await axiosInstance.get(url, { withCredentials: true });

      function response2form(response) {
        const currentYear = new Date().getFullYear();
        const lastThreeYears = [
          currentYear - 1,
          currentYear - 2,
          currentYear - 3,
        ];

        const lastThreeYearsBalance = [
          response.data.balance.filter(
            (item) => lastThreeYears[0] === item.year
          )[0],
          response.data.balance.filter(
            (item) => lastThreeYears[1] === item.year
          )[0],
          response.data.balance.filter(
            (item) => lastThreeYears[2] === item.year
          )[0],
        ];
        const words = response.data.denumire.trim().split(" ");
        const companyForm = words[words.length - 1];
        console.log("Last3: ", lastThreeYearsBalance);

        const financialFields = [
          'year', 'cifra_de_afaceri_neta', 'profit_net', 'active_circulante_total',
          'active_imobilizate_total', 'capitaluri_total', 'datorii_total',
          'venituri_totale', 'cheltuieli_totale', 'profit_brut', 'profit_net', 'pierdere_bruta',
          'casa_si_conturi', 'stocuri', 'creante', 'provizioane', 'venituri_in_avans',
          'cheltuieli_in_avans', 'numar_mediu_de_salariati', 'pierdere_neta'
        ];

        const financialYears = lastThreeYearsBalance.map((balance, index) => {
          if (!balance) return {};

          const yearData = {};
          financialFields.forEach(field => {
            yearData[field] = balance.data[field] || 0;
          });
          return yearData;
        });

        const [financialYear1, financialYear2, financialYear3] = financialYears;

        const form = {
          cui: response.data.cif, // mandatory field
          companyName: response.data.denumire,
          companyForm: companyForm,
          registrationDate: response.data.registration_date.split("T")[0],
          registrationNumber: response.data.numar_reg_com,
          companyAddress: response.data.adresa,
          companyDescription: "",
          authorizedCaenCodes: response.data.authorized_caen_codes,
          companyRegions: [response.data.judet], // mandatory field
          companyCategory: response.data.category,
          financingTypes: ["grant", "loan"],
          tva: response.data.tva,

          // extra fields
          accize: response.data.accize,
          act_autorizare: response.data.act_autorizare,
          cod_postal: response.data.cod_postal,
          fax: response.data.fax,
          impozit_micro: response.data.impozit_micro,
          impozit_profit: response.data.impozit_profit,
          radiata: response.data.radiata,
          telefon: response.data.telefon,
          tva_la_incasare: response.data.tva_la_incasare,
          ultima_declaratie: response.data.ultima_declaratie,

          // financial fields
          financialYear1: financialYear1,
          financialYear2: financialYear2,
          financialYear3: financialYear3,
        };
        return form;
      }
      setFormData({
        ...formData,
        step1: response2form(response),
      });
      setShowSteps(true); // Show the rest of the fields upon successful autocomplete
    } catch (error) {
      toast({
        title: "Error fetching company data",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      const saveResponse = await axiosInstance.post(
        "/companies/save_and_match",
        flattenDictionary(formData),
        { withCredentials: true }
      );
      
      // Only proceed with updates if save was successful
      if (saveResponse.data && saveResponse.data._id) {
        // save current company id in local storage
        dispatch(setCurrentCompany(saveResponse.data._id));
        
        // Fetch updated user data and update Redux state
        const userResponse = await axiosInstance.get("/users/me", { withCredentials: true });
        dispatch(setUserSuccess(userResponse.data));
        
        // reset also companies array in redux
        const companiesResponse = await axiosInstance.get("/companies/getAll", { withCredentials: true });
        dispatch(setCompaniesSuccess(companiesResponse.data));
      }

      // Redirect to feed after successful save
      navigate("/feed");
    } catch (error) {
      console.error('Failed to onboard company:', error);
      toast({
        title: "Error processing request",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // End loading regardless of outcome
    }
  };

  useEffect(() => {
    if (isLoading) {
      // Display loading screen/modal here. You can use a modal, full page overlay, etc.
      // This is just a placeholder logic to indicate where you might implement the visual feedback.
      // console.log("Finding the right fundings for you...");
    }
  }, [isLoading]);

  function flattenDictionary(dict) {
    const flattened = {};

    for (const [key, value] of Object.entries(dict)) {
      if (typeof value === "object" && value !== null) {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          flattened[`${nestedKey}`] = nestedValue;
        }
      } else {
        flattened[key] = value;
      }
    }

    return flattened;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleSearch = async (newFilters) => {
    try {
      setSearchResults([]); // Clear current results
      const response = await axiosInstance.get("/fundings/load/0", {
        params: {
          company_id: currentCompanyId,
          status: newFilters.status !== 'all' ? newFilters.status : null,
          score: filters.eligibility!== 'all' ? filters.eligibility : null,
          region: newFilters.region !== 'all' ? newFilters.region : null,
          deadline_from: newFilters.startDate,
          deadline_to: newFilters.endDate,
          sort_by: newFilters.sortBy !== 'relevance' ? newFilters.sortBy : null,
          sort_order: newFilters.sortOrder,
          text: newFilters.searchQuery
        },
        withCredentials: true,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  console.log("currentIndex: ", currentIndex);
  return (
    <div>
      <Header
        user={user}
        setSearchResults={null}
        filters={filters}
        passedSearchQuery={null}
        onSearch={handleSearch} />
      <Container
        borderRadius={10}
        maxW="800"
        backgroundColor="white"
        paddingBottom={20}
        marginTop={10}
      >
        <StepProgressBar steps={steps} />
      </Container>

      <Step step={0}>
        <Step1Content
          handleAutocomplete={handleAutocomplete}
          data={formData.step1}
          updateData={(newData) => setFormData({ ...formData, step1: newData })}
          showSteps={showSteps}
          setShowSteps={setShowSteps}
          isLoading={isLoading}
          setStep={setStep}
        />
      </Step>
      <Step step={1}>
        <Step2Content
          handleSubmit={handleSubmit}
          data={formData.step2}
          updateData={(newData) => setFormData({ ...formData, step2: newData })}
          setStep={setStep}
        />
      </Step>
      <Step step={2}>
        <Step3Content cui={formData.step1.cui} />
      </Step>
      <Container
        borderRadius={10}
        maxW="800"
        backgroundColor="white"
        padding={5}
        marginTop={5}
      >
        <Flex>
          <Button
            isDisabled={step === 0}
            colorScheme="blue"
            onClick={wrapperStepBarckwards}
          >
            Inapoi
          </Button>
          <Spacer />
          {step === 1 ? (
            <Button colorScheme="green" onClick={wrapperStepForward}>
              Cauta
            </Button>
          ) : (
            <Button colorScheme="blue" onClick={wrapperStepForward}>
              Inainte
            </Button>
          )}
        </Flex>
      </Container>
    </div>
  );
}

export default withStepProgress(Onboard);