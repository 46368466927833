import React, { useEffect, useState } from "react";
import FinanceOption from "./FinanceOption";
import NavigationDrawer from "./NavigationDrawer2";
import Notifications from "./Notifications";
import Header from "../Feed/Header";
import styles from "./Feed2.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loadUserFromStorage, initializeFilters } from "../../reducers/authReducer";
import ScrollToTopButton from "./ScrollToTopButton";
import axiosInstance from "../../services/axios";
import { Button, IconButton } from "@chakra-ui/react";
import { FaThLarge, FaList } from "react-icons/fa";

// New LoadingCard component
const LoadingCard = () => (
  <div className={styles.loadingCard}>
    <div className={styles.loadingAnimation}></div>
  </div>
);

function Feed2() {
  const dispatch = useDispatch();
  const [searchResults, setSearchResultsInner] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [viewMode, setViewMode] = useState(() => localStorage.getItem('viewMode') || 'list');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  const filters = useSelector((state) => state.auth.filters);
  const user = useSelector((state) => state.auth.user);
  const currentCompanyId = useSelector((state) => state.auth.current_company_id);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const location = useLocation();

  const setSearchResults = (results) => {
    if (results.length < 10) {
      setHasMore(false);
      console.log("setSearchResults!");
      setSearchResultsInner(results);
    } else {
      setSearchResultsInner(results);
      setHasMore(true);
    }
  };

  // Load initial fundings
  const loadInitialFundings = async () => {
    try {
      const response = await axiosInstance.get("/fundings/load/0", {
        params: {
          company_id: currentCompanyId,
          // Include all current filters
          status: filters.status !== 'all' ? filters.status : null,
          score: filters.eligibility!== 'all' ? filters.eligibility : null,
          region: filters.region !== 'all' ? filters.region : null,
          deadline_from: filters.startDate,
          deadline_to: filters.endDate,
          sort_by: filters.sortBy !== 'relevance' ? filters.sortBy : null,
          sort_order: filters.sortOrder,
          text: filters.searchQuery
        },
        withCredentials: true,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error loading initial fundings:", error);
    }
  };

  // Initialize app state
  useEffect(() => {
    dispatch(loadUserFromStorage());
    dispatch(initializeFilters()); // Load saved filters from localStorage
    setIsInitialized(true);
    
    const handleScroll = () => {
      setShowScrollButton(window.pageYOffset > 600);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 991);
      if (width <= 991) {
        setViewMode('grid');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle data loading after initialization
  useEffect(() => {
    if (!isInitialized || !user) return;

    if (location.state?.searchQuery) {
      const handleInitialSearch = async () => {
        try {
          setSearchResults([]);
          const response = await axiosInstance.get("/fundings/load/0", {
            params: {
              company_id: currentCompanyId,
              text: location.state.searchQuery,
              ...location.state.filters,
            },
            withCredentials: true,
          });
          setSearchResults(response.data);
          window.history.replaceState({}, document.title);
        } catch (error) {
          console.error("Error during initial search:", error);
        }
      };

      handleInitialSearch();
    } else {
      // If no search query, load all fundings with current filters
      loadInitialFundings();
    }
  }, [location.state, currentCompanyId, filters, isInitialized]); // Added isInitialized to dependencies

  // Handle filter changes
  const handleSearch = async (newFilters) => {
    try {
      setSearchResults([]); // Clear current results
      const response = await axiosInstance.get("/fundings/load/0", {
        params: {
          company_id: currentCompanyId,
          status: newFilters.status !== 'all' ? newFilters.status : null,
          score: filters.eligibility!== 'all' ? filters.eligibility : null,
          region: newFilters.region !== 'all' ? newFilters.region : null,
          deadline_from: newFilters.startDate,
          deadline_to: newFilters.endDate,
          sort_by: newFilters.sortBy !== 'relevance' ? newFilters.sortBy : null,
          sort_order: newFilters.sortOrder,
          text: newFilters.searchQuery
        },
        withCredentials: true,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const toggleViewMode = (newMode) => {
    if (!isMobile && newMode !== viewMode) {
      setViewMode(newMode);
      localStorage.setItem('viewMode', newMode);
    }
  };

  if (isLoading) {
    return (<LoadingCard />);
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (user && !user.is_verified) {
    return <Navigate to="/verify" replace />;
  }

  return (
    <div>
      <Header 
        setSearchResults={setSearchResults} 
        user={user}
        filters={filters}
        passedSearchQuery={location.state?.searchQuery}
        onSearch={handleSearch}
      />
      {!isMobile && (
        <div className={styles.viewToggle}>
          <IconButton
            icon={<FaList size={14} />}
            aria-label="List view"
            aria-pressed={viewMode === 'list'}
            variant="ghost"
            onClick={() => toggleViewMode('list')}
          />
          <IconButton
            icon={<FaThLarge size={14} />}
            aria-label="Grid view"
            aria-pressed={viewMode === 'grid'}
            variant="ghost"
            onClick={() => toggleViewMode('grid')}
          />
        </div>
      )}
      <div className={styles.centered}>
        <div className={`${styles.app} ${viewMode === 'grid' ? styles.gridView : ''}`}>
          <NavigationDrawer className={styles.leftContainer} />
          <FinanceOption 
            searchResults={searchResults} 
            setSearchResults={setSearchResults} 
            filters={filters}
            className={styles.centerContainer}
            hasMore={hasMore}
            setHasMore={setHasMore}
            viewMode={viewMode}
          />
          <div className={`${styles.rightContainer} ${viewMode === 'grid' ? styles.gridView : ''}`}>
          </div>
        </div>
      </div>
      {showScrollButton && <ScrollToTopButton />}
    </div>
  );
}

export default Feed2;
