import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../Feed/Header';
import Footer from '../Landing4/Footer';
import { useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import axiosInstance from '../../services/axios';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
  background: #F9FAFB;
  padding-top: 70px; // Account for fixed header
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: #1A1A1A;
  margin-bottom: 40px;
  font-size: 2.5rem;
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
`;

const PlanCard = styled.div`
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;  // Add this

  ${props => props.popular && `
    border: 2px solid #6B46C1;
    &:before {
      content: 'Popular';
      position: absolute;
      top: 12px;
      right: -30px;
      background: #6B46C1;
      color: white;
      padding: 5px 40px;
      transform: rotate(45deg);
      font-size: 12px;
    }
  `}

  &:hover {
    transform: translateY(-5px);
  }
`;

const PlanName = styled.h2`
  color: #1A1A1A;
  margin-bottom: 15px;
  font-size: 1.5rem;
`;

const PlanPrice = styled.div`
  font-size: 2.5rem;
  color: #6B46C1;
  margin-bottom: 20px;
  font-weight: bold;

  span {
    font-size: 1rem;
    color: #666;
  }
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
//   margin: 0 0 30px 0;
  margin: 0 0 auto 0;  // Change margin-bottom to auto


  li {
    padding: 10px 0;
    color: #4A5568;
    display: flex;
    align-items: center;
    gap: 10px;

    &:before {
      content: '✓';
      color: #6B46C1;
      font-weight: bold;
    }
  }
`;

const SubscribeButton = styled.button`
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: none;
  background: ${props => props.primary ? '#6B46C1' : 'transparent'};
  color: ${props => props.primary ? 'white' : '#6B46C1'};
  border: 2px solid #6B46C1;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(107, 70, 193, 0.2);
  }
`;

const ComparisonTable = styled.div`
  margin-top: 60px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  th, td {
    padding: 15px 20px;
    text-align: left;
    border-bottom: 1px solid #E2E8F0;
  }

  th {
    background: #F7FAFC;
    color: #1A1A1A;
    font-weight: 600;
  }

  td {
    color: #4A5568;
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
  }

  .check {
    color: #6B46C1;
    font-weight: bold;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6B46C1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Subscription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();
  const filters = useSelector((state) => state.auth.filters);
  const currentCompanyId = useSelector((state) => state.auth.current_company_id);
  const [searchResults, setSearchResults] = useState([]);

  const handleSubscription = async (planId) => {
    if (!user) {
      toast({
        title: "Autentificare necesară",
        description: "Te rugăm să te autentifici pentru a te abona",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);

      // Create a Stripe Checkout Session
      const response = await axiosInstance.post('/subscriptions/create-checkout-session', {
        planId,
        userId: user.id,
        successUrl: `${window.location.origin}/subscription/success`,
        cancelUrl: `${window.location.origin}/subscription`,
      });

      const stripe = await stripePromise;
      
      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (error) {
        toast({
          title: "Eroare",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Eroare",
        description: "A apărut o eroare la procesarea plății. Te rugăm să încerci din nou.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error('Subscription error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlanId = (planName) => {
    const planIds = {
      'free': 'price_free',
      'basic': 'price_basic_monthly',
      'professional': 'price_professional_monthly',
      'enterprise': 'price_enterprise_monthly'
    };
    return planIds[planName.toLowerCase()];
  };

  const handleSearch = async (newFilters) => {
    try {
      console.log(currentCompanyId);
      setSearchResults([]); // Clear current results
      const response = await axiosInstance.get("/fundings/load/0", {
        params: {
          company_id: currentCompanyId,
          status: newFilters.status !== 'all' ? newFilters.status : null,
          region: newFilters.region !== 'all' ? newFilters.region : null,
          deadline_from: newFilters.startDate,
          deadline_to: newFilters.endDate,
          sort_by: newFilters.sortBy !== 'relevance' ? newFilters.sortBy : null,
          sort_order: newFilters.sortOrder,
          text: newFilters.searchQuery
        },
        withCredentials: true,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  return (
    <PageWrapper>
      <Header user={user}
        setSearchResults={null}
        filters={filters}
        passedSearchQuery={null}
        onSearch={handleSearch} />
      <MainContent>
        <Container>
          <Title>Alege planul potrivit pentru tine</Title>
          
          <PlansContainer>
            <PlanCard>
              <PlanName>Free</PlanName>
              <PlanPrice>
                0 lei <span>/lună</span>
              </PlanPrice>
              <PlanFeatures>
                <li>Creare cont</li>
                <li>Înrolare manuală 1 firmă</li>
                <li>Filtrare & Sortare</li>
                <li>Afișare status + MVI</li>
              </PlanFeatures>
              <SubscribeButton onClick={() => handleSubscription(getPlanId('free'))}>
                Începe gratuit
              </SubscribeButton>
            </PlanCard>

            <PlanCard>
              <PlanName>Basic</PlanName>
              <PlanPrice>
                100 lei <span>/lună</span>
              </PlanPrice>
              <PlanFeatures>
                <li>Toate beneficiile Free</li>
                <li>Înrolare automată (CUI)</li>
                <li>2 firme înrolate</li>
                <li>Căutare AI limitată</li>
                <li>1 consultație 1:1/lună</li>
                <li>Raport lunar personalizat</li>
              </PlanFeatures>
              <SubscribeButton onClick={() => handleSubscription(getPlanId('basic'))}>
                Alege Basic
              </SubscribeButton>
            </PlanCard>

            <PlanCard popular>
              <PlanName>Professional</PlanName>
              <PlanPrice>
                200 lei <span>/lună</span>
              </PlanPrice>
              <PlanFeatures>
                <li>Toate beneficiile Basic</li>
                <li>3 firme înrolate</li>
                <li>Căutare AI nelimitată</li>
                <li>2 consultații 1:1/lună</li>
                <li>Raport săptămânal</li>
                <li>Pregătire proiect (750€ + 6% CS)</li>
              </PlanFeatures>
              <SubscribeButton 
                primary 
                onClick={() => handleSubscription(getPlanId('professional'))}
              >
                Alege Professional
              </SubscribeButton>
            </PlanCard>

            <PlanCard>
              <PlanName>Enterprise</PlanName>
              <PlanPrice>
                300 lei <span>/lună</span>
              </PlanPrice>
              <PlanFeatures>
                <li>Toate beneficiile Professional</li>
                <li>Firme înrolate nelimitat</li>
                <li>4 consultații 1:1/lună</li>
                <li>Rapoarte complete</li>
                <li>Pregătire proiect (1000€ + 7% CS)</li>
              </PlanFeatures>
              <SubscribeButton onClick={() => handleSubscription(getPlanId('enterprise'))}>
                Alege Enterprise
              </SubscribeButton>
            </PlanCard>
          </PlansContainer>

          <ComparisonTable>
            <Table>
              <thead>
                <tr>
                  <th>Funcționalități</th>
                  <th>Free</th>
                  <th>Basic<br/>100 lei/lună</th>
                  <th>Professional<br/>200 lei/lună</th>
                  <th>Enterprise<br/>300 lei/lună</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Creare cont</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Înrolare manuală</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Înrolare automată (CUI)</td>
                  <td>-</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Număr firme înrolate</td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>Nelimitat</td>
                </tr>
                <tr>
                  <td>Filtrare & Sortare</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Căutare AI</td>
                  <td>-</td>
                  <td>Limitată</td>
                  <td>Nelimitată</td>
                  <td>Nelimitată</td>
                </tr>
                <tr>
                  <td>Afișare status + MVI</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Save + Share</td>
                  <td>-</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Detalii financiare (rezumat)</td>
                  <td>-</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                  <td className="check">✓</td>
                </tr>
                <tr>
                  <td>Consultații 1:1</td>
                  <td>-</td>
                  <td>1/lună</td>
                  <td>2/lună</td>
                  <td>4/lună</td>
                </tr>
                <tr>
                  <td>Raport personalizat</td>
                  <td>-</td>
                  <td>Lunar</td>
                  <td>Săptămânal</td>
                  <td>Săptămânal</td>
                </tr>
                <tr>
                  <td>Pregătire proiect</td>
                  <td>-</td>
                  <td>500€ + 5% CS</td>
                  <td>750€ + 6% CS</td>
                  <td>1000€ + 7% CS</td>
                </tr>
              </tbody>
            </Table>
          </ComparisonTable>
        </Container>
      </MainContent>
      <Footer />
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </PageWrapper>
  );
};

export default Subscription; 