import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../reducers/authReducer";
import { FaFilter, FaSort, FaCalendar, FaMapMarkerAlt, FaChartLine, FaInfoCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./SearchDropdown.css";

const regions = {
  "Nord-Vest": ["Bihor", "Bistrița-Năsăud", "Cluj", "Maramureș", "Satu Mare", "Sălaj"],
  "Nord-Est": ["Bacău", "Botoșani", "Iași", "Neamț", "Suceava", "Vaslui"],
  "Centru": ["Alba", "Brașov", "Covasna", "Harghita", "Mureș", "Sibiu"],
  "Sud-Est": ["Brăila", "Buzău", "Constanța", "Galați", "Tulcea", "Vrancea"],
  "Sud-Muntenia": ["Argeș", "Călărași", "Dâmbovița", "Giurgiu", "Ialomița", "Prahova", "Teleorman"],
  "București-Ilfov": ["București", "Ilfov"],
  "Sud-Vest Oltenia": ["Dolj", "Gorj", "Mehedinți", "Olt", "Vâlcea"],
  "Vest": ["Arad", "Caraș-Severin", "Hunedoara", "Timiș"]
};

const countActiveFilters = (filters) => {
  let count = 0;
  if (filters.status !== "all") count++;
  if (filters.region !== "all") count++;
  if (filters.eligibility !== "all") count++;
  if (filters.startDate) count++;
  if (filters.endDate) count++;
  if (filters.sortBy !== "relevance") count++;
  return count;
};

const SearchDropdown = ({ onSearch }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.auth.filters);
  const user = useSelector((state) => state.auth.user);
  const [activeTab, setActiveTab] = useState('filters'); // 'filters' or 'sort'
  
  // Check if user has any onboarded companies
  const hasOnboardedCompanies = user && user.companies && user.companies.length > 0;

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // Reset eligibility to 'all' if user has no companies and tries to change it
    if (id === 'eligibility' && !hasOnboardedCompanies) {
      return;
    }
    dispatch(setFilters({ [id]: value }));
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    dispatch(setFilters({ sortBy: value }));
  };

  const handleSortOrderChange = (e) => {
    const { value } = e.target;
    dispatch(setFilters({ sortOrder: value }));
  };

  const handleSubmit = () => {
    onSearch(filters);
  };

  const handleResetFilters = () => {
    dispatch(setFilters({
      status: "all",
      region: "all",
      startDate: null,
      endDate: null,
      sortBy: "relevance",
      sortOrder: "desc",
      eligibility: "all"
    }));
  };

  const renderFilterBadge = () => {
    const count = countActiveFilters(filters);
    if (count > 0) {
      return <span className="filter-badge">{count}</span>;
    }
    return null;
  };

  return (
    <div className="search-dropdown">
      <div className="search-dropdown-header">
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'filters' ? 'active' : ''}`}
            onClick={() => setActiveTab('filters')}
          >
            <FaFilter /> Filtre {renderFilterBadge()}
          </button>
          <button 
            className={`tab ${activeTab === 'sort' ? 'active' : ''}`}
            onClick={() => setActiveTab('sort')}
          >
            <FaSort /> Sortare
          </button>
        </div>
      </div>

      <div className="search-dropdown-content">
        {activeTab === 'filters' ? (
          <div className="filters-container">
            <div className="filter-section">
              <div className="section-header">
                <FaChartLine className="section-icon" />
                <h4>Status și Eligibilitate</h4>
              </div>
              <div className="filter-group">
                <select
                  id="status"
                  value={filters.status}
                  onChange={handleInputChange}
                  className="select-input"
                >
                  <option value="all">Status: Toate</option>
                  <option value="active">Activ</option>
                  <option value="inactive">Inactiv</option>
                  <option value="pending">Urmează</option>
                </select>
              </div>
              <div className="filter-group">
                <select
                  id="eligibility"
                  value={filters.eligibility}
                  onChange={handleInputChange}
                  className="select-input"
                  disabled={!hasOnboardedCompanies}
                >
                  <option value="all">Scor: Toate</option>
                  <option value="potrivit">Potrivit</option>
                  <option value="posibil">Posibil</option>
                  <option value="nepotrivit">Nepotrivit</option>
                </select>
                {!hasOnboardedCompanies && (
                  <div className="no-company-message">
                    <FaInfoCircle className="info-icon" />
                    <div className="message-content">
                      <span>Pentru a filtra după scor, te rugăm să adaugi mai întâi o companie</span>
                      <Link to="/onboard2" className="onboard-link">Adaugă o companie →</Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="filter-section">
              <div className="section-header">
                <FaMapMarkerAlt className="section-icon" />
                <h4>Locație</h4>
              </div>
              <div className="filter-group">
                <select
                  id="region"
                  value={filters.region}
                  onChange={handleInputChange}
                  className="select-input"
                >
                  <option value="all">Toate regiunile</option>
                  {Object.entries(regions).map(([regionName, counties]) => (
                    <optgroup key={regionName} label={regionName}>
                      {counties.map((county) => (
                        <option key={county} value={county}>
                          {county}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </div>
            </div>

            <div className="filter-section">
              <div className="section-header">
                <FaCalendar className="section-icon" />
                <h4>Interval de timp active</h4>
              </div>
              <div className="date-inputs">
                <div className="filter-group">
                  <input
                    type="date"
                    id="startDate"
                    value={filters.startDate || ''}
                    onChange={handleInputChange}
                    className="date-input"
                    placeholder="Data de început"
                  />
                </div>
                <div className="filter-group">
                  <input
                    type="date"
                    id="endDate"
                    value={filters.endDate || ''}
                    onChange={handleInputChange}
                    className="date-input"
                    placeholder="Data de sfârșit"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="sort-container">
            <div className="sort-section">
              <h4>Criteriu de sortare</h4>
              <div className="sort-options">
                <label className="sort-option">
                  <input
                    type="radio"
                    name="sort"
                    value="score"
                    checked={filters.sortBy === "score"}
                    onChange={handleSortChange}
                  />
                  <span className="radio-label">Scor</span>
                </label>
                <label className="sort-option">
                  <input
                    type="radio"
                    name="sort"
                    value="deadline"
                    checked={filters.sortBy === "deadline"}
                    onChange={handleSortChange}
                  />
                  <span className="radio-label">Data de închidere</span>
                </label>
                <label className="sort-option">
                  <input
                    type="radio"
                    name="sort"
                    value="start"
                    checked={filters.sortBy === "start"}
                    onChange={handleSortChange}
                  />
                  <span className="radio-label">Data de începere</span>
                </label>
              </div>
            </div>

            <div className="sort-section">
              <h4>Direcție</h4>
              <div className="sort-options">
                <label className="sort-option">
                  <input
                    type="radio"
                    name="sortOrder"
                    value="asc"
                    checked={filters.sortOrder === "asc"}
                    onChange={handleSortOrderChange}
                  />
                  <span className="radio-label">Ascendent</span>
                </label>
                <label className="sort-option">
                  <input
                    type="radio"
                    name="sortOrder"
                    value="desc"
                    checked={filters.sortOrder === "desc"}
                    onChange={handleSortOrderChange}
                  />
                  <span className="radio-label">Descendent</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="search-dropdown-footer">
        {countActiveFilters(filters) > 0 && (
          <button 
            className="reset-button" 
            onClick={handleResetFilters}
          >
            <IoClose /> Resetează
          </button>
        )}
        <button className="apply-button" onClick={handleSubmit}>
          Aplică filtrele
        </button>
      </div>
    </div>
  );
};

export default SearchDropdown;
